export const BUSINESS_CODE_MODE = {
  SUCCESS:'SUCCESS', //业务成功
  USER_MOBILE_FORMAT_ERROR:'USER_MOBILE_FORMAT_ERROR',//用户名或密码错误
  USERNAME_OR_PASSWORD_FREE_ERROR:'USERNAME_OR_PASSWORD_FREE_ERROR', //用户名或密码错误
}

/**
 * 登录验证提示语
 * @type {{USERNAME_OR_PASSWORD_FREE_ERROR: string}}
 */
export const LOGIN_CHECK_MESSAGE = {
  USERNAME_OR_PASSWORD_FREE_ERROR:'用户名或密码错误！', //登录验证提示
  LOGIN_SUCCESSFUL:'欢迎登录禹步信息live平台！',
}

/**
 * 用户中心字典码
 * @type {{enum}}
 */
export const USER_CENTER_CODE = {
    USER_ALREADY_EXISTS:'USER_ALREADY_EXISTS', //用户已经存在
}


/**
 * 账号列表显示字段
 * @type {{Array}
 */
export const accountListColumns = [
    {
        title: '用户姓名',
        key:'name',
    },
    {
        title: '登录帐号',
        key:'phone',
    },
    {
        title: '角色权限',
        key:'roleName',
    },
    {
        title: '人员编号',
        slot:'人员编号',
    },
    {
        title: '操作',
        slot:'操作',
        align: 'center',
        width:220,
    },
]

