/**
 * 账号管理
 * @type {{}}
 */
import Vue from "vue";
import { Input, Icon, Select, Option, Message, Table, Page, Modal,Button,Progress } from "view-design";
import { accountListColumns,USER_CENTER_CODE,getAccountListService,getRoleListService,userAddService,userUpdateService,delUserService,resetUserPasswordService,updateUserPasswordService } from '@/service/user-service'
Vue.component('Input', Input);
Vue.component('Icon', Icon);
Vue.component('Select', Select);
Vue.component('Option', Option);
Vue.component('Table', Table);
Vue.component('Page', Page);
Vue.component('Modal', Modal);
Vue.component('Button', Button);
Vue.component('Progress', Progress);
import { onSysncUploadFiles } from "@/utils/request-function";
import { systemUploadType } from "@/service/system-service";
import $event, { EVENT_NAME } from "@/utils/event-emitter";
import ViewImage from '@/components/ViewImage/viewImage.vue'
const AccountList = {
    name:'AccountList',
    components:{ ViewImage },
    data() {
        return {
            accountLoading:false, //loading
            accountListColumns:accountListColumns, //账号列表显示字段
            accountModalVisible:false, //新增/编辑弹框状态
            updatePasswordVisible:false, //修改密码弹框状态
            editLoading:false, //编辑loading
            updatePasswordLoading:false, //修改密码loading
            speedSingleVisible:false, //单文件上传进度可显示
            deviceListHeight:0,
            imageName:'',
            imageSrc:'', //上传缩略图
            complete:0, //上传进度
            viewImageVisible:false,
            manufacturerArray:[], //生产厂家数据
            selectAllCancel:[], //全选时的临时存储
            accountListArray:{}, //账号列表数据
            roleListArray:[], //权限列表
            btnStatus:false, //按钮状态
            qrCodeVisible:false, //code modal 可显示
            QRCode:'',
            logoSrc:require("@/assets/Icon/logo-img.png"),
            accountEditParams:{
                name:'',
                mobile:'',
                roleId:0,
                password:'',
                id:'',
                profilePicture:'',
                profilePictureUrl:'',
                userCode:'',
            },
            updatePasswordParams:{
                id:'',
                newPassword:'',
                confirmPassword:'',
            },
            accountListParams:{ //提交的数据集
                name:'',  //用户名
                mobile:'', //手机号
                currentPage: 1,  //当前页
                displayNumber:10 // 显示条数
            }
        }
    },
    created() {
        this.onAccountListAll().then()
        this.onTableHeight()
        /**
         * 进度条数值
         */
        $event.$on(EVENT_NAME.UPLOAD_PROGRESS_COMPLETE, (n) => {
            this.complete = n
        })
    },
    mounted() {
        window.addEventListener('resize', this.onTableHeight)
    },
    methods: {
        /**
         * 计算table高度
         */
        onTableHeight(){
            this.deviceListHeight = window.innerHeight - 183
            this.windowWidth = window.innerWidth
        },
        /**
         * 重置
         */
        onReset() {
            this.accountListParams.name = ''
            this.accountListParams.mobile = ''
        },
        /**
         * 搜索
         */
        onSearch(){
            this.onAccountListAll().then()
        },
        /**
         * 图片放大
         * @param name
         * @param src
         */
        onImageAmplify(name,src) {
            this.imageName = name
            this.imageSrc = src
            this.viewImageVisible = true
        },
        /**
         * 清空
         */
        onClearImage() {
            this.$refs.pathClear.value = ''
            this.accountEditParams.profilePicture = ''
            this.accountEditParams.profilePictureUrl = ''
        },
        /**
         * 新增
         */
        onAddList() {
            this.accountModalVisible = true
            this.btnStatus = true
            this.onRoleList().then()
            this.accountEditParams.name = ''
            this.accountEditParams.mobile = ''
            this.accountEditParams.roleId = ''
            this.accountEditParams.password = ''
            this.accountEditParams.profilePicture = ''
            this.accountEditParams.profilePictureUrl = ''
            this.accountEditParams.userCode = ''
        },
        /**
         * 编辑
         * @param obj
         */
        onEditList(obj){
            this.accountModalVisible = true
            this.btnStatus = false
            this.onRoleList().then()
            this.accountEditParams.name = obj.name
            this.accountEditParams.mobile = obj.phone
            this.accountEditParams.roleId = obj.roleId
            this.accountEditParams.id = obj.id
            this.accountEditParams.profilePicture = obj.profilePicture
            this.accountEditParams.profilePictureUrl = obj.profilePictureUrl
            this.accountEditParams.userCode = obj.userCode
        },
        /**
         * 修改密码
         * @param id
         */
        onUpdatePassword(id){
            this.updatePasswordVisible = true
            this.updatePasswordParams.id = id
        },
        /**
         * 提交验证
         */
        onSubmitCheck() {
            if(this.accountEditParams.name === ''){
                Message.error('请输入用户姓名！');
                return;
            }
            if(this.accountEditParams.mobile === ''){
                Message.error('请输入登录帐号！');
                return;
            }
            if(this.accountEditParams.roleId === 0){
                Message.error('请选择用户身份！');
                return;
            }
            if(this.accountEditParams.password === '' && this.btnStatus === true){
                Message.error('请输入密码！');
                return;
            }
            if(this.btnStatus){
                this.onUserAdd().then()
            }else{
                this.onUserUpdate().then()
            }
        },
        /**
         * 提交修改密码
         */
        onSubmitUpdatePasswordCheck(){
            if(this.updatePasswordParams.newPassword === ''){
                Message.error('请输入密码！');
                return;
            }
            if(this.updatePasswordParams.newPassword.length <6){
                Message.error('密码不可少于6位！');
                return;
            }
            if(this.updatePasswordParams.newPassword!==this.updatePasswordParams.confirmPassword){
                Message.error('密码和确认密码不相符！');
                return;
            }
            this.onUpdateUserPasswordService().then()
        },
        /**
         * 删除
         * @param id
         */
        onDelete(id) {
            Modal.confirm({
                title: '提示',
                content: '<p>您确定要删除这条信息吗？</p>',
                onOk: () => {
                    this.onDelUser(id).then()
                }
            });
        },
        /**
         * 重置密码提示
         * @param id
         */
        onResetPassword(id) {
            Modal.confirm({
                title: '提示',
                content: '<p>重置密码,将密码设置为123456!</p>',
                onOk: () => {
                    this.onResetUserPassword(id).then()
                }
            });
        },

        /**
         * 发送当前页
         * @param number
         */
        onSearchPage(number){
            this.accountListParams.currentPage = number
            this.onAccountListAll().then()
        },
        /**
         * 发送每页显示的条数
         * @param number
         */
        onSearchPageSize(number){
            this.accountListParams.displayNumber = number
            this.onAccountListAll().then()
        },
        /**
         * 头像上传文件
         * @param event
         * @returns {Promise<void>}
         */
        async onAccountFileChange(event){
            const file =  event.target.files[0];
            this.fileType = file.type
            if (!['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)) {
                Message.warning('请上传正确的图片')
                return
            }
            if (file.size > 5 * 1024 * 1024) {
                Message.warning('请选择5M以内的图片')
                return
            }
            this.speedSingleVisible = true
            onSysncUploadFiles([file],systemUploadType.user_avatar_image).then((data)=>{
                this.speedSingleVisible = false
                this.accountEditParams.profilePictureUrl = data[0].url
                this.accountEditParams.profilePicture = data[0].resourceName
                this.complete = 0
            }).catch(()=>{
                this.speedSingleVisible = false
                this.complete = 0
                Message.error('上传失败，请重新尝试！')
            })
        },
        /**
         * 查询账号列表
         */
        async onAccountListAll() {
            try {
                this.accountLoading = true
                this.accountListArray = {}
                let params = {
                    name:this.accountListParams.name,
                    mobile:this.accountListParams.mobile,
                    displayNumber:this.accountListParams.displayNumber,
                    currentPage:this.accountListParams.currentPage
                }
                const { code,data } = await getAccountListService(params)
                if(code === 'SUCCESS'){
                    this.accountListArray = data
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.accountLoading = false
            }catch (error){
                this.accountLoading = false
                console.log(error)
            }
        },
        /**
         * 添加用户
         */
        async onUserAdd() {
            try {
                this.editLoading = true
                let params = {
                    name:this.accountEditParams.name,
                    mobile:this.accountEditParams.mobile,
                    roleId:this.accountEditParams.roleId,
                    password:this.accountEditParams.password,
                    profilePicture:this.accountEditParams.profilePicture,
                    userCode:this.accountEditParams.userCode
                }
                const { code } = await userAddService(params)
                if(code === 'SUCCESS'){
                    this.accountModalVisible = false
                    this.onAccountListAll().then()
                }else{
                    switch (code){
                        case USER_CENTER_CODE.USER_ALREADY_EXISTS:
                            Message.error('该用户已存在，不可重复添加！')
                            break;
                        default:
                            Message.error('请求异常，请联系管理员！')
                            break;
                    }
                }
                this.editLoading = false
            }catch (error){
                this.editLoading = false
                console.log(error)
            }
        },
        /**
         * 修改用户
         */
        async onUserUpdate() {
            try {
                this.editLoading = true
                let params = {
                    name:this.accountEditParams.name,
                    mobile:this.accountEditParams.mobile,
                    roleId:this.accountEditParams.roleId,
                    id:this.accountEditParams.id,
                    profilePicture:this.accountEditParams.profilePicture,
                    userCode:this.accountEditParams.userCode
                }
                const { code } = await userUpdateService(params)
                if(code === 'SUCCESS'){
                    this.accountModalVisible = false
                    this.onAccountListAll().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.editLoading = false
            }catch (error){
                this.editLoading = false
                console.log(error)
            }
        },
        /**
         * 获取当前公司的角色
         */
        async onRoleList() {
            try {
                const { code,data } = await getRoleListService()
                if(code === 'SUCCESS'){
                    this.roleListArray = data
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 删除用户
         */
        async onDelUser(id) {
            try {
                let params = {
                    id:id
                }
                const { code } = await delUserService(params)
                if(code === 'SUCCESS'){
                    Message.success('删除成功！')
                    this.onAccountListAll().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 重置密码
         */
        async onResetUserPassword(id) {
            try {
                let params = {
                    id:id
                }
                const { code } = await resetUserPasswordService(params)
                if(code === 'SUCCESS'){
                    Message.success('重置成功！')
                    this.onAccountListAll().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 修改密码
         */
        async onUpdateUserPasswordService() {
            try {
                this.updatePasswordLoading = true
                let params = {
                    id:this.updatePasswordParams.id,
                    password:this.updatePasswordParams.newPassword
                }
                const { code } = await updateUserPasswordService(params)
                if(code === 'SUCCESS'){
                    this.updatePasswordVisible = false
                    Message.success('修改成功！')
                    this.onAccountListAll().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.updatePasswordLoading = false
            }catch (error){
                this.updatePasswordLoading = false
                console.log(error)
            }
        },
    },
    /**
     * 销毁事件
     */
    destroyed() {
        $event.$off([EVENT_NAME.UPLOAD_PROGRESS_COMPLETE])
        window.removeEventListener('resize',this.onTableHeight)
    }
}
export default AccountList
