import  request from '@/utils/request-utils'
import { appConfig } from '@/config/app-config';
const evnUrl = appConfig.domain.httpUrl
export * from './enum'
/**
 * post 请求 用户登录换取token
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const userSignInService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/authentication/user/background/login'),params,'login')
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 请求 查询账号列表
 * @param formData
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getAccountListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/company/user/list?name='+params.name+'&mobile='+params.mobile+'&size='+params.displayNumber+'&page='+params.currentPage))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 请求 获取当前公司的角色
 * @param formData
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getRoleListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/company/role/list'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 添加用户
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const userAddService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/company/user/add'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 修改用户
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const userUpdateService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/company/user/update'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 删除用户
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const delUserService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/company/user/delete'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 重置密码
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const resetUserPasswordService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/company/user/reset/password'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 修改密码
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const updateUserPasswordService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/company/user/update/password'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 请求 获取权限数据
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getUserSystemPower = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/system/company/system/power?companyId='+params.companyId))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 请求 获取权限菜单数据
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getUserSystemMenuList = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/authentication/user/system/power'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 修改用户信息
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const updateUserInfo = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/authentication/user/background/updateUser'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
